/* Add user btn */
.add-user.btn {
    padding: 0.5rem 0.75rem;
}


@media (min-width: 1000px) {
    .modal-dialog.lg {
        max-width: 1000px;
    }
    .user_card_box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: -1.25rem;
    }
    .user_card {
        width: calc((100% - (1.25rem * 2)) / 3);
        margin-right: 1.25rem;
    }
    .user_card:nth-child(3n) {
        margin-right: 0;
    }
}

.user_card:hover {
    background-color: #e8eaed;
    cursor: pointer;
}
.user_card.active {
    background-color: #AFB7C0;
    cursor: pointer;
}

.user_card {
    border: 1px solid #eaeaea;
    border-radius: 5px;
    padding: 10px;
    height: 75px;
    margin-bottom: 1.25rem;
    background-color: #fff;
    overflow: hidden;
}
.user_card .title {
    text-transform: none;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
}
.user_card .subtitle {
    font-style: italic;
    font-size: 12px;
}
.user_card p {
    margin-bottom: 0;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.add-user-modal .modal-footer p {
    margin-bottom: 0;
}
.saving-icon {
    font-size: 18px;
    margin: 0 5px 0 10px;
}
.saving-icon.ok {
    color: green;
    margin-right: 5px;
}
.saving-icon.error {
    color: red;
}

/* Tabs */
@media (min-width: 1000px) {
    .search-container .tabs {
        display: flex;
        align-items: center;
    }
}
.search-container .tabs > div {
    margin-right: 20px;
}
.search-container .tabs .tab {
    border: 1px solid #ccc;
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 6px 30px;
    background-color: #fff;
    cursor: pointer;
}
.search-container .tabs .tab.active {
    background-color: rgba(0, 0, 0, 0.03);
}
.search-container .tabs button:focus {
    outline: none;
}
.search-container .content {
    border: 1px solid #ccc;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background-color: rgba(0, 0, 0, 0.03);
    padding: 1.25rem;
}
.search-container .inner {
    padding-bottom: 1.25rem;
}
.search-container .search .form-group {
    margin-bottom: 0;
}
.search-container .search p {
    font-style: italic;
    font-size: 10px;
    margin-bottom: 0;
    margin-top: 2px;
    margin-left: 5px;
}
.group-member p {
    overflow: hidden;
    text-overflow: ellipsis;
}

/* list members - single */
.group-member {
    padding: 0.5rem 0.75rem;
    display: flex;
    width: 75%;
    justify-content: space-between;
}
.group-member:nth-child(odd){
    background-color: rgba(0, 0, 0, 0.03);
}
.group-member:hover {
    background-color: #eaeaea;
}
.group-member p {
    margin-bottom: 0;
}
.group-member .icon {
    cursor: pointer;
}

@media (max-width: 600px) {
    .group-member {
        width: 100%;
    }
}

/* list members - double */
.list-group-members.column-2 {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.column-2 .group-member {
    width: 50%;
}
.column-2 .group-member:nth-child(4n+1), .column-2 .group-member:nth-child(4n+2) {
    background-color: rgba(0, 0, 0, 0.03);
}
.column-2 .group-member:nth-child(4n+3), .column-2 .group-member:nth-child(4n+4) {
    background-color: #fff;
}
