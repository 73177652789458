


/* GENERAL */
header {
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.navbar {
    background: #0e303b;
}
.navbar-toggler {
    color: #eaeaea;
}
.nav-link, .nav-link:hover, .nav-link:active, .nav-link:focus, .navbar-nav .show > .nav-link {
    color: #eaeaea;
    font-size: 14px;
}
.user_info {
    margin-top: 0,5rem;
    color: #eaeaea;
    font-size: 14px;
}
.dropdown-menu, .dropdown-item:hover {
    background: #0e303b;
    color: #eaeaea;
}
.user_dropdown .icon, .log-out-icon svg {
    margin-right: 0.6rem;
}
.navbar-toggler:focus {
    box-shadow: none;
}
.log-out-icon {
    white-space: nowrap;
    cursor: pointer;
}


/* MOBILE */
.navbar .mobile-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.navbar-brand .logo {
    height: 50px;
}
.dropdown-menu, .dropdown-item {
    padding-top: 0;
    padding-bottom: 0;
}
.user-menu-item .dropdown-menu {
    display: block;
    margin-top: 0.5rem;
}
.user-menu-item .user-menu-icon {
    display: none;
}
.user-menu-item .dropdown-item {
    padding: 0.5rem 0;
}
.user-menu-item .dropdown-item:first-child {
    border-top: 1px solid gray;
    padding-top: 1rem;
}
.user-menu-item .dropdown-item:last-child {
    padding: 0;
}
.user_info > div {
    margin-bottom: 0.3rem;
}
.user-menu-item .dropdown-item .user-menu-logout {
    border: none;
}

/*TABLET*/
@media (min-width: 700px) {
    .navbar-collapse {
        border-top: 1px solid gray;
        padding: 0.5rem 1.5rem 0.5rem;
        margin-top: 0.5rem;
    }
    .navbar-nav {
        flex-direction: row;
    }
    .md-half {
        width: 50%;
    }
    .user-menu-item .dropdown-item:first-child {
        border: none;
        padding-top: 0.5rem;
    }
    .user-menu-item .user_dropdown {
        margin: 0;
    }
}

/*DESKTOP*/
@media (min-width: 992px) {
    .navbar .container-fluid {
        max-width: 1140px;
    }
    .navbar-brand .logo {
        height: 70px;
    }
    .navbar-collapse {
        border-top: none;
        margin-top: 0;
    }
    .md-half {
        width: 100%;
    }
    .md-half:first-child {
        display: flex;
    }
    .user-menu-item .user-menu-icon {
        display: block;
    }
    .user-menu-item {
        border-left: 1px solid #eaeaea;
        margin-left: 2rem;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 2rem;
    }
    .user-menu-item .dropdown-menu {
        display: none;
    }
    .user-menu-item .dropdown-menu.show {
        display: block;
    }
    .navbar-expand-lg .navbar-nav .dropdown-item .nav-link {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .dropdown-menu {
        padding-bottom: .5rem;
        padding-top: .5rem;
    }
    .user_dropdown {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .navbar-expand-lg .navbar-nav .user_dropdown {
        right: -20px;
    }
    .user_info {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}
