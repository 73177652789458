
@media (min-width: 600px) {
    .bg {
        margin: 0 10%;
    }
}

p, table, li {
    font-size: 14px;
}
.table td {
    padding: 0.4rem 0.75rem;
}
.row h3 {
    margin-bottom: 20px;
}
.vertical_center {
    margin: auto;
}
.btn-secondary {
    border-radius: 0;
    padding: 10px 15px;
    margin-right: 10px;
    background-color: #d3d3d3;
    color: #000;
}
.btn-secondary:hover {
    background-color: #c2c2c2;
    color: #000;
}
.btn-primary {
    background-color: #556271;
    color: #fff;
}
.btn-primary:hover {
    background-color: #4f5b69;
    color: #fff;
}
.right {
    float: right;
    padding: 0 0.5rem;
}
.body {
    min-height: calc(100vh - 40px);
    padding-top: 76px;
}

@media (min-width: 992px) {
    .body {
        padding-top: 96px;
    }
}

.btn.icon-btn, .btn.icon-btn:first-child:active {
    color: #7b8898;
    background-color: transparent;
    border: none;
    padding: 0 0 0 3em;
}

@media (min-width: 768px) {
    .btn.icon-btn, .btn.icon-btn:first-child:active {
        padding: 0 20px;
    }
}


h1, h2, h3 {
}
h3 {
    font-size: 1.5rem;
}
h4 {
    font-size: 1.2rem;
}

body {
    font-family: 'Poppins', sans-serif;
}

.padding {
    padding: 50px;
}

.warning {
    color: orangered;
}

.search-form {
    margin-left: 15px;
}

.serviceAccount {
    display: flex;
    align-items: center;
}
.serviceAccount input {
    margin-right: 20px;
}
.width600 {
    max-width: 600px;
}
.information {
    display: flex;
}
.information svg {
    margin-right: 10px;
}

a {
    text-decoration: none;
}
.form-control:disabled {
    cursor: not-allowed;
}

.overwiew-table th {
    border-bottom: 2px solid gray;
}

@media (min-width: 1400px) {
    .container {
        max-width: 1140px;
    }
}

.btn-flowsync, .btn-flowsync:hover, .btn-flowsync:active {
    padding: 0.75rem 1rem;
    background: #0e303b;
    color: #eaeaea;
    border-radius: 0;
}
