
.guides-menu {
    background-color: #0e303b;
    padding: 1rem 2rem;
}
    .guides-menu a, .guides-menu a:hover, .guides-menu a:focus {
        color: #eaeaea;
    }
.nav {
    flex-wrap: nowrap;
}
.nav-right {
    padding: 2rem;
}
.guides-menu .navbar-nav {
    flex-direction: row;
}
.guides-menu .nav-item {
    margin-right: 1rem;
}
.guides-row img {
    width: 100%;
}
.guides-row h3 {
    margin-top: 2rem;
}

.help-scrollable {
    scroll-margin-top: 64px;
}

@media (min-width: 600px) {
    .guides-menu .collapse:not(.show) {
        display: block;
    }
}
@media (min-width: 992px) {
    .guides-row {
        display: flex;
    }
    .guides-menu {
        padding: 2rem;
    }
    .nav-left {
        padding: 0;
        background-color: #0e303b;
        height: 100%;
        position: fixed;
        z-index: 1;
        left: 0;
        overflow-x: hidden;
        width: 300px;
    }
    .nav-right {
        margin-left: 300px;
        width: 1000px;
    }
    .guides-menu .navbar-toggler {
        display: none;
    }
    .guides-menu .navbar-nav {
        flex-direction: column;
    }

    .help-scrollable {
        scroll-margin-top: 116px;
    }
}