

.page h1 {
    text-transform: uppercase;
    font-weight: 900;
    margin: 20px 0;
}

.page {
    background-color: #eaeaea;
    min-height: calc(100vh - 76px - 40px);
    padding-top: 30px;
    padding-bottom: 30px;
}

@media (min-width: 992px) {
    .page {
        min-height: calc(100vh - 96px - 40px);
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.instance-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 30px;
}

@media (min-width: 768px) {
    .instance-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 1200px) {
    .instance-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

.instance-single .is_header {
    background-color: #0e303b;
    color: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (min-width: 992px) {
    .instance-single .is_header {
        height: 80px;
    }
}
.instance-single h5 {
    margin: 0;
    width: 90%;
}
.instance-single h5 > a {
    color: #fff;
}
.instance-single .is_body {
    padding: 20px;
    background: #fff;
    min-height: 100px;
}
.instance-single .is_body p {
    overflow-wrap: break-word;
}
.instance-single .is_footer {
    background: #fff;
    padding: 20px;
    border-top: 2px solid #0e303b;
    display: flex;
    justify-content: space-around;
}
.instance-single .is_footer .svg-inline--fa {
    color: #0e303b;
    font-size: 20px;
}

.icon_active {
    font-size: 1.5rem;
}
.red {
    color: red;
}
.yellow {
    color: yellow;
}
.green {
    color: green;
}