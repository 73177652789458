

.about .hero {
    background-image: url("../images/timothy-meinberg-mU2tmikgrwE-unsplash.jpg");
    min-height: 60vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
}
.about .hero .text {
    padding: 50px;
    color: #000;
    z-index: 2;
    background: #EAEAEA;
    margin: 50px 0;
}
.about .hero .text h1 {
    text-transform: uppercase;
    font-weight: 900;
}
.about .content {
    background: #eaeaea;
    z-index: 2;
    position: relative;
    padding: 20px;
}
       

.about .content_box {
    display: flex;
}

.content_box img {
    width: 100%;
    box-shadow: 10px 10px 10px darkgray;
}
.about h3 {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 20px;
}
.about .content_box.reverse {
    flex-direction: column-reverse;
}
.about .content_box .image {
    padding: 20px;
}
@media (min-width: 600px) {
    .about .hero {
        padding: 50px 0;
    }
    .about .content {
        margin-top: -50px;
        padding: 100px 40px 0;
    }
    .about .content_box {
        padding-bottom: 100px;
    }
    .about .content_box .right {
        padding-right: 25px;
        text-align: right;
    }
    .about .content_box .left {
        padding-left: 25px;
    }
    .about .content_box.reverse {
        flex-direction: row;
    }
    .about .content_box .image {
        padding: 0 15px;
    }
    .content_box_vertical {
        flex-direction: column;
    }
    .content_box_vertical .text {
        max-width: 50%;
        margin: 0 auto;
    }

}

/*Logotypes*/
.logotypes {
    background-color: #fff;
    padding: 20px;
}

@media (min-width: 600px) {
    .logotypes {
        padding: 50px 0;
    }
    .logotypes h3 {
        text-align: center;
    }
}
/*Logos*/
.logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
    .logos img {
        max-width: 100px;
        padding: 20px;
    }

@media (min-width: 600px) {
    .logos img {
        max-width: 200px;
        padding: 20px 40px;
    }
}


/*Contact*/
.about .contact {
    background-image: url("../images/kristine-weilert-tLNRTxieD7k-unsplash.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    position: relative;
}
.about .contact .text {
    padding: 20px;
    color: #000;
    right: 0;
    z-index: 2;
    background: #EAEAEA;
    margin: 80px 20px;
}
@media (min-width: 600px) {
    .about .contact .text {
        margin: 80px -15px;
        margin-left: auto;
        width: 60%;
        padding: 80px;
    }
}

.image-bg {
    background: #fff;
    padding: 20px;
}

