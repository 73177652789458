.app-token-consent {
    min-height: calc(100vh - 116px);
    background-image: url("../images/sebastian-unrau-sp-p7uuT0tw-unsplash.jpg");
}

.app-token-consent__wrapper {
    display:flex;
    padding: 1rem;
    min-height: 66%;
}

.app-token-consent__container {
    background-color: #EAEAEA;
    padding: 2rem;
}

.app-token-consent__header {
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 1.5rem;
}

.app-token-consent__parameters {
   margin-top: 2rem;
}

@media (min-width: 720px) {
    .app-token-consent__container {
        max-width: 780px;
        background-color: #EAEAEA;
        padding: 4rem;
    }

    .app-token-consent__wrapper {
        display: flex;
        padding: 4rem;
        justify-content: center;
        height: 66%;
    }
}

@media (min-width: 992px) {
    .app-token-consent {
        min-height: calc(100vh - 136px);
    }
}