.pagination-box {
    text-align: center;
    padding-top: 20px;
}

    /* Removing wierd defaults in Chrome */
    .pagination-box > ul {
        margin-block-end: 0;
        margin-bottom: .8rem;
    }

.pagination {
    border: 1px solid #dee7e7;
    display: inline-flex;
    color: #7B8898;
}

    .pagination li {
        padding: 10px 20px;
        border-right: .5px solid #dee7e7;
        border-left: .5px solid #dee7e7;
    }

    .pagination > li {
        cursor: pointer;
    }

        .pagination > li:hover {
            background-color: #d8e1ed;
        }

    .pagination .active {
        color: #fff;
        cursor: default;
        background-color: #7B8898;
    }

        .pagination .active:hover {
            background-color: #7B8898;
        }

    .pagination .disabled {
        color: #dee7e7;
        cursor: default;
        background-color: inherit;
    }

        .pagination .disabled:hover {
            background-color: inherit;
        }

.pagination-text {
    font-size: .8rem;
}

.nobreak {
    white-space: nowrap;
}

.showOnLg {
    display: none;
}

.hideOnLg {
    display: block;
}

@media (min-width: 992px) {
    .pagination-box {
        padding: 40px 0;
    }

    .showOnLg {
        display: block;
    }

    .hideOnLg {
        display: none;
    }
}
