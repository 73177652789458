.spinner {
    /* Spinner size and color */
    width: 3rem;
    height: 3rem;
    border-top-color: #444;
    border-left-color: #444;
    /* Additional spinner styles */
    animation: spinner 400ms linear infinite;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
}

/* Animation styles */
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner-slow {
    animation: spinner 1s linear infinite;
}

.spinnerSmall {
    width: 2rem;
    height: 2rem;
    margin-top: 20px;
}

.spinner-header {
    width: 2rem;
    height: 2rem;
    margin-left: 20px;
}

.center {
    text-align: center;
    width: 100%;
    margin-top: 100px;
}

.inline {
    display: inline-block;
}
