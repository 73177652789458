

.home .hero {
    background-image: url("../images/sebastian-unrau-sp-p7uuT0tw-unsplash.jpg");
    min-height: 80vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
}
.home .text {
    color: #000;
    background: #EAEAEA;
    padding: 20px;
}
.home .text h1 {
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 1.5rem;
}
.home .text h2 {
    font-size: 20px;
}
.btn-box {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}
    .btn-box .btn {
        padding: 0.75rem 1rem;
        background: #0e303b;
        color: #eaeaea;
        border-radius: 0;
    }

.home .icon-boxes {
    padding-top: 50px;
    padding-bottom: 50px;
    background: #eaeaea;
    position: relative;
    z-index: 2;
    margin-bottom: 50px;
}
    .home .icon-boxes div {
        text-align: center;
        padding-right: 50px;
        padding-left: 50px;
        padding-bottom: 50px;
    }
.icon-box {
    background-color: #0e303b;
    color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px;
}
    .icon-box .icon {
        font-size: 30px;
    }


@media (min-width: 600px) {
    .home .hero {
        padding: 50px;
    }
    .home .text {
        padding: 80px;
        width: 60%;
        z-index: 2;
        margin: 50px -15px;
    }
    .home .icon-boxes {
        margin-top: -50px;
    }
        .home .icon-boxes div {
            padding-bottom: 0;
        }
    .btn-box {
        justify-content: flex-start;
    }
    .btn-box .btn {
        margin-right: 20px;
    }

}