
.add_user-btn {
    margin-top: 2rem;
}
.user_permissions-table thead th {
    border-bottom: 2px solid gray;
    border-top: none;
}
.bold {
    font-weight: bold;
}
.permission-list {
    display: flex;
    flex-direction: column;
    padding: 0;
}
.permission-list li {
    display: inline;
    background-color: #e8eaed;
    border-radius: 3px;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ccc;
    width: fit-content;
    margin: .3rem 0;
}
@media (min-width: 600px) {
    .edit-user {
        cursor: pointer;
        width: 50px;
    }
}

.add-permission {
    display: flex;
    justify-content: space-between;
}
    .add-permission button {
        margin-right: 0;
        margin-left: 1rem;
        padding: 0.375rem 0.75rem;
        height: calc(2.25rem + 2px);
    }

.add-permission .form-group {
    flex-grow: 1
}

.remove-permissionlevel {
    border: none;
    background: none;
    cursor: pointer;
    margin-left: 2rem;
}

.remove-user {
    margin-top: 1.5rem;
    cursor: pointer;
    color: blue;
    background: none;
    border: none;
    font-size: 14px;
    padding: 0;
}
.remove-user:disabled, .remove-permissionlevel:disabled {
    cursor: not-allowed;
    color: gray;
}
.title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    margin-left: 15px;
}

/*Modal*/
.modal-footer {
    justify-content: flex-start;
}
.modal-footer p {
    margin-bottom: 0;
}

/*responsive*/
@media (max-width: 600px) {
    .table thead {
        display: none;
    }
    .table tr {
        display: block;
        border-bottom: 1px solid #ddd;
    }
    .table td {
        display: block;
        border: none;
        text-align: right;
    }
    .table td::before {
        content: attr(data-label);
        float: left;
        font-weight: 600;
        padding-right: 10px;
    }
    .table tbody tr:nth-child(odd) {
        background: #f5f5f5;
    }
    .add-permission {
        display: block;
    }
    .add-permission button {
        margin-top: 1rem;
        margin-left: 0;
    }
}