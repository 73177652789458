
.subfooter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}
.subfooter img {
    width: 100px;
    margin-left: 5px;
}
.subfooter p {
    margin-bottom: 0;
    margin-right: 5px;
}
